// session-storage.service.ts
import { Injectable } from '@angular/core';
import { EncryptionDecryptionService } from './encryption-decryption.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(private encryptionService: EncryptionDecryptionService) { }

  private urlKey = "pakage-boundle"
  private tokenKey = "sample"
  private rolesKey = "json-package"

  setPermissions(data: any): void {
    const encryptedData = this.encryptionService.encrypt(data);
    sessionStorage.setItem(this.urlKey, encryptedData);
  }

  getPermissions(): any {
    const encryptedData = sessionStorage.getItem(this.urlKey);
    if (encryptedData) {
      return this.encryptionService.decrypt(encryptedData);
    }
    return null;
  }

  removePermissions(): any {
    sessionStorage.removeItem(this.urlKey);
  }

  setUserRoles(data: any): void {
    const encryptedData = this.encryptionService.encrypt(data);
    sessionStorage.setItem(this.rolesKey, encryptedData);
  }

  getUserRoles(): any {
    const encryptedData = sessionStorage.getItem(this.rolesKey);
    if (encryptedData) {
      return this.encryptionService.decrypt(encryptedData);
    }
    return null;
  }

  removeUserRoles(): any {
    sessionStorage.removeItem(this.rolesKey);
  }

  setUserToken(data: any): void {
    const encryptedData = this.encryptionService.encrypt(data);
    sessionStorage.setItem(this.tokenKey, encryptedData);
  }

  getUserToken(): any {
    const encryptedData = sessionStorage.getItem(this.tokenKey);
    if (encryptedData) {
      return this.encryptionService.decrypt(encryptedData);
    }
    return null;
  }

  removeUserToken(): void {
    sessionStorage.removeItem(this.tokenKey);
  }
}
