// encryption-decryption.service.ts
import { Injectable } from '@angular/core';
import * as CryptoTS from 'crypto-ts';

@Injectable({
  providedIn: 'root',
})
export class EncryptionDecryptionService {
  private secretKey = 'rio81708hu3i98797hj'; // Replace with a strong secret key

  encrypt(data: any): string {
    const encryptedData = CryptoTS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
    return encryptedData;
  }

  decrypt(encryptedData: string): any {
    const decryptedData = CryptoTS.AES.decrypt(encryptedData, this.secretKey).toString(CryptoTS.enc.Utf8);
    return JSON.parse(decryptedData);
  }
}
